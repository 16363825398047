body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
canvas {
  background-color: transparent !important;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 10, 2024 */



@font-face {
  font-family: 'euclid_circular_bbold';
  src: url('/fonts/euclid_circular_b_bold-webfont.woff2') format('woff2'),
       url('/fonts/euclid_circular_b_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'euclid_circular_blight';
  src: url('/fonts/euclid_circular_b_light-webfont.woff2') format('woff2'),
       url('/fonts/euclid_circular_b_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'euclid_circular_bmedium';
  src: url('/fonts/euclid_circular_b_medium-webfont.woff2') format('woff2'),
       url('/fonts/euclid_circular_b_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'euclid_circular_bregular';
  src: url('/fonts/euclid_circular_b_regular-webfont.woff2') format('woff2'),
       url('/fonts/euclid_circular_b_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunar_escaperegular';
  src: url('/fonts/lunar_escape-webfont.woff2') format('woff2'),
       url('/fonts/lunar_escape-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fadeEffect {
  
  height: auto;
  transition: opacity 0.2s ease-in-out;
}

/* Fade-in and fade-out animation */
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.fadeInOut {
  animation: fadeInOut 1s infinite; /* 2 seconds duration, repeats infinitely */
}

