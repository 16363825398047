.distribution-map {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.distribution-map > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  margin: 0;
  padding: 0;
}

/* Área específica de Australia */
.australia-area {
  position: absolute;
  top: 65%;
  left: 80%;
  width: 10%;
  height: 10%;
  clip-path: polygon(
    20% 0%,    /* Punta norte */
    0% 20%,    /* Costa noroeste */
    10% 50%,   /* Costa oeste */
    0% 80%,    /* Costa suroeste */
    20% 100%,  /* Punta sur */
    80% 90%,   /* Costa sureste */
    100% 60%,  /* Costa este */
    90% 30%,   /* Costa noreste */
    60% 0%     /* Costa norte */
  );
  pointer-events: none;
}

.distribution-map .map-point {
  cursor: pointer;
  outline: none;
  z-index: 0;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  background: rgba(238, 15, 229, 0.2);
  border: 3px solid #EE0FE5;
  padding: 0;
  box-shadow: 0 0 20px rgba(238, 15, 229, 0.5);
}

.distribution-map .map-point:hover {
  background: rgba(238, 15, 229, 0.4);
  box-shadow: 0 0 30px rgba(238, 15, 229, 0.7);
}

.distribution-map .map-point .content {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  overflow: overlay;
}

.distribution-map .map-point:active,
.distribution-map .map-point:focus {
  margin: 0;
  padding: 0;
  opacity: 1;
  width: 200px;
  height: 120px;
  color: #e5e5e5;
  z-index: 1;
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid #EE0FE5;
  box-shadow: 0 0 30px rgba(238, 15, 229, 0.5);
}

.distribution-map .map-point:active .content,
.distribution-map .map-point:focus .content {
  opacity: 1;
  transition: opacity 0.25s ease-in-out 0.25s, height 0.25s ease-in-out, overflow 0.25s ease-in-out;
  overflow: hidden;
}

.centered-y {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.distribution-map .map-point h2 {
  color: #fff;
  font-size: 18px;
  margin: 0 0 8px 0;
  font-weight: 500;
}

.distribution-map .map-point p {
  color: #EE0FE5;
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

.marker-point {
  position: absolute;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 3;
}

.marker-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #1FE4FE;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px rgba(31, 228, 254, 0.8);
  transition: all 0.3s ease;
}

/* Animación de pulso */
.marker-point::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(31, 228, 254, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.8;
  }
  70% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
}

/* Efectos hover */
.marker-point:hover .marker-center {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 0 25px rgba(31, 228, 254, 0.9);
}

/* Popup de información */
.info-popup {
  position: absolute;
  top: -120px;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  background-color: rgba(39, 44, 67, 0.95);
  border: 1px solid rgba(31, 228, 254, 0.3);
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(31, 228, 254, 0.2);
  backdrop-filter: blur(5px);
}

.info-popup.visible {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1);
}

.info-popup h3 {
  color: #1FE4FE;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.info-popup p {
  color: #ffffff;
  margin: 5px 0;
  font-size: 14px;
  text-align: center;
  opacity: 0.8;
}

/* Animación de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#map-container {
  animation: fadeIn 0.5s ease-out;
}

/* Estilos del contenedor del mapa */
#map-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Estilos del SVG del mapa */
#allSvg {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

/* Display de tiempo */
#time-display {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #1FE4FE;
  font-size: 24px;
  font-weight: 300;
  text-shadow: 0 0 10px rgba(31, 228, 254, 0.5);
  z-index: 2;
}

#time {
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 2px;
}

.allPaths {
  fill: rgb(64, 64, 64);
  stroke: rgb(64, 64, 64);
  stroke-width: 0.5;
  transition: all 0.3s ease;
}

.country-name {
  position: absolute;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  opacity: 0;
  border-radius: 5px;
  border: 3px solid rgb(245, 128, 128);
  padding: 0px 5px;
  font-size: 1.5rem;
}

.time-container {
  background-color: white;
  width: fit-content;
  font-size: 1.5rem;
  padding: 5px 5px;
  border-radius: 5px;
  border: 3px solid rgb(245, 128, 128);
}

.map-container {
  height: 100%;
  overflow: hidden;
}

svg {
  width: 100%;
  height: 100%;
} 